import React, {
  useEffect, useRef, useState, useCallback
} from 'react';
import io from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'firebase/analytics';
import InputEmoji from 'react-input-emoji';
import ReactPlayer from 'react-player';
import uuid from 'react-uuid';
import useWebSocket from 'react-use-websocket';
import { sha256 } from 'js-sha256';
import Modal from '../../Components/Modal/Modal';
import Navbar from '../../Components/Navbar/Navbar';
import Button from '../../Components/Button/Button';

import {
  APP_NAME,
  ROCKET_CHAT,
  WEBSOCKET_ROCKET_CHAT,
  TEACHER_USERNAME,
  TEACHER_PASSWORD,
  SOCKET_URL
} from '../../Constants/Constants';

import Calendar from '../../Assets/Images/calendar.png';
import Kids from '../../Assets/Images/kids_white.png';
import Send from '../../Assets/Images/send.png';
import Back from '../../Assets/Images/back_arrow_black.png';
import Upload from '../../Assets/Images/upload.png';

import { retrieve as accountActionRetrieve } from '../../Generated/actions/account/show';
import { retrieve as teacherActionRetrieve } from '../../Generated/actions/teacher/show';
import { retrieve as liveClassActionRetrieve } from '../../Generated/actions/liveclass/show';

import './LiveClassPage.scss';

moment.tz.setDefault('Asia/Jakarta');

const LiveClassPage = () => {
  // Socket
  const socket = io(SOCKET_URL);

  // States
  const History = useHistory();

  const dispatch = useDispatch();

  const location = useSelector((state) => state.router.location.pathname);
  const accountDetails = useSelector((state) => state.account.show);
  const teacherDetails = useSelector((state) => state.teacher.show);
  const liveClassDetails = useSelector((state) => state.liveclass.show);

  const language = useSelector((state) => state.language.change);
  const langData = language.lang ? language.lang.data : {};

  // Rocketchat
  const [connect, setConnect] = useState(false);
  const [message, setMessage] = useState([]);
  // const [playerTime, setPlayerTime] = useState(false);
  const [text, setText] = useState('');
  const [modal, setModal] = useState(false);
  // const [playing, setPlaying] = useState(true);

  const [userId, setUserId] = useState();
  const [authToken, setAuthToken] = useState();
  const [roomID, setRoomID] = useState(0);

  // Socket requirement
  const [userJoin, setUserJoin] = useState(true);
  const [progress, setProgress] = useState({});

  // UI
  const player = useRef(null);
  const bottomChat = useRef(null);
  const inputFile = useRef(null);

  const {
    sendMessage,
    lastMessage,
  } = useWebSocket(WEBSOCKET_ROCKET_CHAT);

  // Effects
  useEffect(() => {
    document.title = `${langData.teacherPage} - ${APP_NAME}`;
  });

  const scrollToBottomChat = () => {
    if (bottomChat.current !== null) {
      bottomChat.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // useEffect(() => {
  //   console.log(progress);
  // }, [progress]);

  const connectRocketChat = useCallback(() =>
    sendMessage(
      JSON.stringify({ msg: 'connect', version: '1', support: ['1'] })
    ), [sendMessage]);

  useEffect(() => {
    dispatch(accountActionRetrieve());
    dispatch(teacherActionRetrieve());
  }, [dispatch]);

  useEffect(() => {
    if (!liveClassDetails.retrieved) {
      const stringToSend = `${location.split('/')[2]}`;
      dispatch(liveClassActionRetrieve(stringToSend));
    }
  }, [liveClassDetails, location, dispatch]);

  useEffect(() => {
    if (liveClassDetails.retrieved && userJoin) {
      socket.emit('user-join', {
        user_id: liveClassDetails.retrieved.teacher.id,
        name: liveClassDetails.retrieved.teacher.name,
        role: 'teacher',
        avatar_image_url: liveClassDetails.retrieved.teacher.profile_picture,
        live_class_schedule_id: liveClassDetails.retrieved.id
      });

      // console.log('ontime');
      setUserJoin(false);
    }
  }, [liveClassDetails, userJoin, socket]);

  useEffect(() => {
    if (teacherDetails.retrieved && accountDetails.retrieved && liveClassDetails.retrieved && !connect) {
      const stringToSend = `${location.split('/')[2]}`;
      if (parseFloat(stringToSend) !== liveClassDetails.retrieved.id) {
        window.location.reload();
      }
      // setPlayerTime(1);
      setConnect(true);
    }
  }, [teacherDetails.retrieved, accountDetails.retrieved, liveClassDetails.retrieved, location, connect]);

  // const reSyncVideo = () => {
  //   if (liveClassDetails.retrieved) {
  //     const start = moment(liveClassDetails.retrieved.start);
  //     const diff = moment.duration(start.diff()).asSeconds();

  //     if (player.current && playerTime) {
  //       player.current.seekTo(diff * -1);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (player.current && playerTime) {
  //     if (playerTime >= player.current.getDuration()) {
  //       player.current.seekTo(0);
  //     } else {
  //       const start = moment(liveClassDetails.retrieved.start);
  //       const diff = moment.duration(start.diff()).asSeconds();

  //       if (player.current && playerTime) {
  //         player.current.seekTo(diff * -1);
  //       }
  //     }
  //   }
  // }, [player, playerTime, liveClassDetails.start, liveClassDetails.retrieved]);

  const statusOnGoing = (start, end) => {
    const startMoment = moment(start);
    const endMoment = moment(end);
    if (moment().isBetween(startMoment, endMoment)) {
      return <div className="ongoing">On Going</div>;
    }
    return <div></div>;
  };

  useEffect(() => {
    if (lastMessage && accountDetails.retrieved && liveClassDetails.retrieved) {
      const data = JSON.parse(lastMessage.data);
      if (data.msg === 'ping') {
        sendMessage(JSON.stringify({ msg: 'pong' }));
      }
      if (data.msg === 'connected' || data.reason === 'Already connected') {
        sendMessage(JSON.stringify({
          msg: 'method',
          method: 'login',
          id: 'login-to-rocket-chat',
          params: [
            {
              // user: { username: accountDetails.retrieved.username },
              user: { username: TEACHER_USERNAME },
              password: {
                // digest: sha256('rocketchatpintar'),
                digest: sha256(TEACHER_PASSWORD),
                algorithm: 'sha-256'
              }
            }
          ]
        }));
      }
      if (data.id === 'login-to-rocket-chat' && data.result) {
        setRoomID(liveClassDetails.retrieved.roomId);
        setUserId(data.result.id);
        setAuthToken(data.result.token);
        sendMessage(JSON.stringify({
          msg: 'sub',
          id: 'stream-messages',
          name: 'stream-room-messages',
          params: [
            liveClassDetails.retrieved.roomId,
            false
          ]
        }));
        sendMessage(JSON.stringify({
          msg: 'method',
          method: 'loadHistory',
          id: 'load-history',
          params: [liveClassDetails.retrieved.roomId, null, 20, null]
        }));
      }
      if (data.msg === 'result' && data.result && data.result.messages) {
        setMessage(data.result.messages.reverse());
      }
      if (data.msg === 'changed' && data.collection === 'stream-room-messages') {
        if (data.fields && data.fields.args) {
          const found = message.findIndex((el) => el._id === data.fields.args[0]._id);
          if (found < 0) {
            const newChat = data.fields.args[0];
            setMessage([...message, newChat]);
            scrollToBottomChat();
          } else {
            const newChat = data.fields.args[0];
            const oldMessage = message;
            oldMessage[found] = newChat;
            setMessage(oldMessage);
          }
        }
      }
    } else {
      connectRocketChat();
    }
    // eslint-disable-next-line
  }, [lastMessage, accountDetails.retrieved]);

  // Sending a messages
  const userSendMessage = () => {
    if (roomID) {
      sendMessage(JSON.stringify({
        msg: 'method',
        method: 'sendMessage',
        id: 'send-message',
        params: [
          {
            _id: uuid(),
            rid: roomID,
            msg: text
          }
        ]
      }));

      setText('');
    }
  };

  const handleUploadFile = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    fetch(`${ROCKET_CHAT}/api/v1/rooms.upload/${roomID}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'X-User-Id': userId,
        'X-Auth-Token': authToken,
      },
      body: data
    }).then(() => {
    }).catch(() => {
    });
  };

  socket.on('send-progress', (data) => {
    setProgress(data);
    // console.log(data);
  });

  socket.on('user-error', () => {
    // console.log(data);
  });

  socket.on('send-video', (data) => {
    const newData = {};
    newData.progressContent = 0;
    if (data.video && data.video.correct) {
      newData.progressData = data.video;
      newData.currentProgress = 'path';
    } else {
      newData.progressData = data.video;
      newData.currentProgress = 'neutral';
    }

    setProgress(newData);
    // console.log(data);
  });

  socket.on('send-pop-quiz', () => {
    const newData = {};
    newData.progressContent = 0;
    newData.currentProgress = 'pop-quiz';
    newData.progressData = {
      type: 'pop-quiz'
    };

    setProgress(newData);
    // console.log('pop-quiz');
  });

  // Render Functions
  const renderHeader = () => {
    return (
      <div className='live-class-page-header' >
        {liveClassDetails.retrieved
          && statusOnGoing(liveClassDetails.retrieved.start, liveClassDetails.retrieved.end)}
        <span className="live-class-page-header-small">{liveClassDetails.retrieved
          && liveClassDetails.retrieved.topic.category.subject.translations[1].name}</span>
        <span className="live-class-page-header-heading">{liveClassDetails.retrieved
          && liveClassDetails.retrieved.topic.translations[1].name}</span>
        <div className='live-class-page-header-calendar'>
          <img src={Calendar} alt="time-live-classes" />
          {liveClassDetails.retrieved
            && moment.utc(liveClassDetails.retrieved.start, 'YYYY-MM-DD H:m:s').format('h:mm a, MMMM Do, YYYY')}
        </div>
      </div>
    );
  };

  const renderChat = (Chat) => {
    if (Chat && (Chat.md || Chat.attachments)) {
      return (
        <div className="chat" key={Chat._id} >
          <img src={`${ROCKET_CHAT}/avatar/${Chat.u.username}`} alt={Chat.u.username} />
          <div className="message">
            <b> {Chat.u.name} {Chat.u.username === TEACHER_USERNAME ? (<span>Teacher</span>) : ''} </b>
            {Chat.md ? (
              <span> {Chat.msg} </span>
            ) :
              Chat.attachments.map((image) => (
                <img src={`${ROCKET_CHAT}${image.image_url}`} alt={image.name} className='attachment' />
              ))
            }
          </div>
        </div>
      );
    }
    return '';
  };

  const handleEnter = () => {
    userSendMessage();
  };

  const renderLoginModal = () => {
    return (
      <Modal setModal={setModal}>
        <div className="modal-content">
          <h3>
            Anda akan mengahkiri kelas langsung.
          </h3>
          <p>Perintah ini tidak dapat diulang kembali</p>
          {/* <Button
            block
            type="secondary"
            // type={ !showSubmitButton ? 'disabled' : ''}
            onClick={() => console.log('End Session')}
          >
            {langData.imsure}
          </Button> */}
          <Button
            block
            // type={ !showSubmitButton ? 'disabled' : ''}
            onClick={() => setModal(false)}
          >
            {langData.nowait}
          </Button>
        </div>
      </Modal>
    );
  };

  const currentContent = (data) => {
    switch (data.currentProgress) {
      case 'neutral':
        return (
          <div className="content-video">
            <b>Neutral Video {data.progressData.sorting_order + 1}</b>
            <ReactPlayer
              ref={player}
              url={`${data.progressData.video_url}#t=${data.progressContent}`}
              width="100%"
              controls={true}
              playing={true}
              muted={true}
            />
          </div>
        );

      case 'path':
        return (
          <div>
            <div className="content-video">
              <b>Correct Kids Video {data.progressData.correct.sorting_order + 1}</b>
              <ReactPlayer
                ref={player}
                // eslint-disable-next-line max-len
                url={`${data.progressData.correct.video_url}#t=${data.progressData.correct.progressContent}`}
                width="100%"
                controls={true}
                playing={true}
                muted={true}
              />
            </div>
            <div className="content-video wrong">
              <b>Incorrect Kids Video {data.progressData.wrong.sorting_order + 1}</b>
              <ReactPlayer
                ref={player}
                url={`${data.progressData.wrong.video_url}#t=${data.progressData.wrong.progressContent}`}
                width="100%"
                controls={true}
                playing={true}
                muted={true}
              />
            </div>
          </div>
        );

      default:
        return (
          <div className="pop-quiz">Kids still answering pop up Quiz! Give them spirit!</div>
        );
    }
  };

  // console.log('rerender');

  return (
    <div className='page-container live-class-page' >
      {modal ? renderLoginModal() : ''}
      <Navbar />
      <div className='body-content' >
        <img src={Back} alt="go-back"
          className="live-class-page-back"
          onClick={() => History.goBack()}
        />
        {renderHeader()}
        <div className='live-class-page-border' />
        <div className='live-class-page-content'>
          {progress.progressData
            ? (
              currentContent(progress)
            ) : (
              <div className="info">Live class is not started yet</div>
            )}
          <br />

          {/* <div className="control">
            <Button
              onClick={() => reSyncVideo()}
            >
              Resync First Video
            </Button> */}
          {/* <Button
              type="danger"
              onClick={() => setModal(true)}
            >End Session
            </Button> */}
          {/* </div> */}

          <div className="live-class-page-live-chat-class">
            <div className="header-live-chat">
              Pesan Kelas Langsung

              <div className="user-joined">
                <img src={Kids} alt='user-joined' />
                {liveClassDetails.retrieved && liveClassDetails.retrieved.user_joined}
              </div>
            </div>

            <div className="content-live-chat">
              <br />
              {message.length > 1 ? message.map((Chat) => {
                return renderChat(Chat);
              }) : (<div className="no-chat">Belum ada chat</div>)}

              <br />
              <br />
              <br />
              <div ref={bottomChat}></div>
            </div>

            <div className="footer-live-chat">
              <InputEmoji
                value={text}
                onChange={setText}
                cleanOnEnter
                onEnter={handleEnter}
                placeholder="Tulis pesan ..."
              />
              <input
                type="file"
                className="form-control"
                name="upload_file"
                ref={inputFile}
                onChange={handleUploadFile}
                style={{ display: 'none' }}
              />
              <img src={Upload} alt="Upload" onClick={() => { inputFile.current.click(); }} />
              <img
                src={Send}
                alt="Upload"
                onClick={() => handleEnter()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveClassPage;
